<template>
    <el-row>
        <el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
            <Breadcrumb :title="$t(category + '.' + key)"
                :titleList="[$t('menu.home'), $t('industry.' + category), $t(category + '.' + key)]"></Breadcrumb>
            <el-row>
                <el-col :xs="0" :sm="0" :md="6" :lg="6" :xl="6">
                    <el-table :data="industry.datas" :style="{ width: '100%', marginBottom: '40px' }"
                        :header-row-style="{ color: 'rgb(0, 111, 164)' }">
                        <el-table-column prop="title" :label="$t('industry.' + category)">
                            <template #default="scope">
                                <div :style="{
                                    background: scope.row.key === key ? 'rgb(142, 195, 31)' : 'rgb(229, 238, 209)'
                                }">
                                    <el-button type="text"
                                        :style="{ marginLeft: '10px', color: scope.row.key === key ? '#ffffff' : 'rgb(0, 111, 164)', fontSize: scope.row.key === key ? '16px' : '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }"
                                        @click="handleChangeKey(scope.row.key)">{{
                                            $t(industry.category + '.' + scope.row.key) }}</el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>
                <el-col :xs="24" :sm="24" :md="18" :lg="18" :xl="18">
                    <el-main>
                        <el-container v-for="(desc, index) in item.description" :key="index" style="margin-bottom: 20px;">
                            <div class="description">{{ $t(industry.category + '.' + desc) }}</div>
                        </el-container>
                        <el-container>
                            <el-row>
                                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-for="(path, index) in item.images"
                                    :key="index">
                                    <el-image :src="path" fit="fill" :preview-src-list="item.images" :initial-index="index"
                                        :hide-on-click-modal="true"></el-image>
                                </el-col>
                            </el-row>
                        </el-container>
                    </el-main>
                </el-col>
            </el-row>
        </el-col>
        <el-col :xs="0" :sm="0" :md="0" :lg="5" :xl="5"></el-col>
    </el-row>
</template>
    
<script>
import industries from "/public/Json/industry.json";
import Breadcrumb from "../../components/Breadcrumb.vue"

export default {
    name: "Common",
    components: {
        Breadcrumb
    },
    data() {
        return {
            category: this.$route.params.category,
            key: this.$route.params.key,
            industries,
            industry: {},
            item: {}
        };
    },
    mounted: function () {
        this.industry = industries.datas.find(i => i.category === this.category)
        if (this.industry) {
            this.item = this.industry.datas.find(i => i.key === this.key)
        }
    },
    methods: {
        handleChangeKey(key) {
            this.$router.push({
                path: `/Industry/${this.industry.category}/${key}`,
            });
        }
    }
};
</script>
    
<style scoped>
:deep(.el-table__header-wrapper) {
    font-size: large;
}

:deep(.el-table--enable-row-hover .el-table__body tr:hover > td) {
    background: rgb(142, 195, 31) !important;
}

.description {
    text-indent: 2em;
}
</style>